<template>
    <div class="referrer">
        <!--导航-->
        <rxNavBar title="选择房间"></rxNavBar>
        <div class="communityDiv">
            <van-search  placeholder="请输入搜索关键词" v-model="searchInfo" size="large" @input="getRenterRoomHouseList()"/>
        </div>
        <div class="roomList" v-for="(item,index) in roomHouseList" :key="index">
            <div class="room" @click="chooseRoom(index)" v-if="item.subType != 7">{{item.roomDetailedAddress}}</div>
        </div>
    </div>
</template>

<script>
    import { NavBar,search} from 'vant';
    import {getRenterRoomHouseList} from "../../../getData/getData";
    import {getStaffId, responseUtil} from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            [NavBar.name]:NavBar,
            [search.name]:search,
            rxNavBar
        },
        data() {
            return {
                searchInfo:'',//搜索关键字
                roomHouseList:[],
                roomInfo:''
            }
        },
        mounted() {
            this.flagType = this.$route.query.flagType
            this.getRenterRoomHouseList()
        },
        methods: {
            //列表初始化
            // flagType //1.修改2.续租3.转租4.换房  5.潜客进入6.房源进入7.潜客预定，房源预定，预定合同进去
            getRenterRoomHouseList(){
                let that = this
                let queryData = {}
                queryData.staff_id = getStaffId()
                queryData.searchInfo = that.searchInfo
                queryData.flagType = this.flagType
                getRenterRoomHouseList(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {

                        that.roomHouseList = response.data.data.data
                        // alert(that.roomHouseList.length)
                    })
                });
            },

            chooseRoom(index){
                this.roomInfo = this.roomHouseList[index]
                let data = {
                    roomInfo:this.roomInfo
                }
                eventBus.$emit('selectContractRoomList',data);
                //调用router回退页面
                this.$router.go(-1);
            }
        },

        //路由跳转时销毁全局bus里的监听事件
        beforeDestroy() {
            eventBus.$off('selectContractRoomList')
        }
    }
</script>

<style lang="less" scoped >
    .communityDiv{
        height: 60px;
    }
    .room{
        height: 35px;
        line-height: 35px;
        width: 345px;
        margin-left: 15px;
        margin-bottom: 15px;
        background-color: white;
        border-radius: 8px;
        font-size: 15px;
        text-align: center;
    }
</style>
